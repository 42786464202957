import React from 'react';
import Carreras from '../../Components/InfoCarreras/Carreras';
import Patrocinios from '../../Components/Patrocinios/Patrocinios';

function Index() {
    return (
        <div>
            <Carreras />
            <Patrocinios /> 
        </div>
    );
}

export default Index;